<template>
  <div class="admodal" >
    <div id="adContent" class="admodal__content" :class="getClass"  :width="getSize.w"
        :height="getSize.h">
      <div class="loader-container">
        <AdLoader />
      </div>

      <div id="hHelper"></div>
      <!-- <div v-if="getType" id="psydoContent" class="psydoContent">
        <div class="hl">
          <h2>
            DRAG IT! <br />
            <span class="sl">rechts unten...</span>
          </h2>
          <p>
            Spiel mit der Größe rum!
          </p>
        </div>
        <div class="">
          <div class="psydoBoxIn"></div>
        </div>
        <div class="">
          <div class="psydoBoxIn"></div>
        </div>

        <div v-for="num in 10" :key="num" class="psydoBox">
          <div class="psydoBoxIn"></div>
        </div>
      </div> -->

      <!-- <div>{{ getAdPath }}</div> -->
      <iframe
        id="adFrame"
        :src="getAdPath"
        :width="getSize.w"
        :height="getSize.h"
        frameborder="0"
      ></iframe>

      <div
        id="sizeBtn"
        v-if="getType"
        @click.prevent
        @mousedown="isDragging = true"
        @touchstart="test"
        @mouseup="isDragging = false"
        :class="isDragging ? 'is-dragging' : ''"
      >
        <div id="sizeBtnCircle_1"></div>
        <div id="sizeBtnCircle_2"></div>
        <div class="sizeBtnText">{{ dragText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import AdLoader from "./AdLoader";
import { computed, onMounted, ref, watchEffect, onUpdated } from "vue";
import { useStore } from "vuex";
export default {
  components: {
    AdLoader,
  },
  setup() {
    onUpdated(() => {
      console.log("<---------  onUpdated   ------------_>");
      let adFrame = document.querySelector("#adFrame");
      adFrame.addEventListener("load", function() {
        adFrame.style.visibility = "visible";
      });
    });

    const store = useStore();

    const ID = Math.random()
      .toString(36)
      .substr(2, 9);

    const getAdPath = computed(() => {
      if (store.state.forceTestDay) {
        return (
          store.state.activeComponent.path +
          store.state.activeComponent.ending +
          "?" +
          ID +
          "&testDay=" +
          store.state.forceTestDay
        );
      } else {
        return (
          store.state.activeComponent.path +
          store.state.activeComponent.ending +
          "?" +
          ID
        );
      }
    });
    const dragText = computed(() => {
      return !isDragging.value ? "Drag me!" : "OMG!";
    });

    const getType = computed(() => {
      return store.state.activeComponent.type === "ds" ||
        store.state.activeComponent.type === "fireplace" ||
        store.state.activeComponent.type === "responsive"
        ? true
        : false;
    });
    const getClass = computed(() => {
      return getType.value ? "flex-start" : "";
    });

    const isDragging = ref(false);

    const getSize = computed(() => {
      if (
        store.state.activeComponent.type === "ds" ||
        store.state.activeComponent.type === "fireplace" ||
        store.state.activeComponent.type === "wallpaper"
      ) {
        return { w: "100%", h: "100%" };
      } else {
        return store.state.activeComponent.size;
      }
    });

    function test(e) {
      console.log(e);
    }
    function closeModal() {}

    // function setPoints(el, x, y) {
    //   //Todo replace width dynamic padding Calc
    //   let newX = x > window.innerWidth ? window.innerWidth : x;
    //   let newY = y > window.innerHeight ? window.innerHeight : y;

    //   console.log(x, window.innerWidth, y, window.innerHeight);
    //   el.style.left = newX - 50 + "px";
    //   el.style.top = newY - 50 + "px";
    // }

    // function setAdFrameSize(el, width, height, helper) {
    //   let wW = width - el.getBoundingClientRect().x;
    //   let w = wW < 160 ? 160 : wW;

    //   let hH = height - el.getBoundingClientRect().y;
    //   let h = hH < 600 ? 600 : hH;
    //   h =
    //     h > helper.getBoundingClientRect().height
    //       ? helper.getBoundingClientRect().height
    //       : h;

    //   let psydoContent = document.getElementById("psydoContent");
    //   el.style.width = w + "px";
    //   el.style.height = psydoContent.style.height = h + "px";
    // }

    //TODO adding Control for Touchevents

    function initClickhandle() {
      // let closeAd = document.getElementById("closeAd");
      //   let adFrame = document.getElementById("adFrame");
      // closeAd.addEventListener("mousemove", passEvent);
      // function passEvent(e) {
      //   console.log(e);
      //   // adFrame.contentWindow.postMessage(e);
      // }
      //DRAGGING FUNKTION__ START
      //   let sizeBtn = document.getElementById("sizeBtn");
      //   let hHelper = document.getElementById("hHelper");
      //   sizeBtn.addEventListener("mousedown", startDrag);
      //   function startDrag(e) {
      //     setPoints(sizeBtn, e.clientX, e.clientY);
      //     setAdFrameSize(adFrame, e.clientX, e.clientY, hHelper);
      //     sizeBtn.removeEventListener("mousemove", dragging);
      //     window.addEventListener("mousemove", dragging);
      //     window.addEventListener("mouseup", stopDragging);
      //   }
      //   function dragging(e) {
      //     setPoints(sizeBtn, e.clientX, e.clientY);
      //     setAdFrameSize(adFrame, e.clientX, e.clientY, hHelper);
      //   }
      //   function stopDragging(e) {
      //     e.preventDefault();
      //     e.stopPropagation();
      //     setPoints(sizeBtn, e.clientX, e.clientY);
      //     setAdFrameSize(adFrame, e.clientX, e.clientY, hHelper);
      //     sizeBtn.addEventListener("mousedown", startDrag);
      //     window.removeEventListener("mousemove", dragging);
      //     window.removeEventListener("mouseup", stopDragging);
      //   }
      // sizeBtn.style.top = adFrame.getBoundingClientRect().y +adFrame.getBoundingClientRect().height;
      //  sizeBtn.style.left = adFrame.getBoundingClientRect().y +adFrame.getBoundingClientRect().height;
    }

    //DRAGGING FUNKTION __END

    onMounted(() => {
      if (getType.value) {
        initClickhandle();
      }
    });

    watchEffect(() => {
      if (store.state.activeComponent) {
        console.log("-----------_> CHANGE <-_-----------------");
        try {
          let adFrame = document.querySelector("#adFrame");
          adFrame.style.visibility = "hidden";
        } catch (error) {
          console.log("no iframe");
        }
      }
    });
    return {
      closeModal,
      getAdPath,
      getSize,
      getType,
      getClass,
      isDragging,
      dragText,
      test,
    };
  },
};
</script>

<style lang="scss" scoped>
#adFrame{
  top:0;
  left: 0;
  // position: absolute;
  // 
}
.admodal {
  user-select: none;
  background-color: rgba($color: rgb(228, 228, 228), $alpha: 1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  overflow: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 0;
  & * {
    user-select: none;
  }
}

.flex-start {
  align-items: flex-start !important;
}
.admodal__content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media screen and (min-width: $medium) {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  @media screen and (min-width: $large) {
  }
  @media screen and (min-width: $xlarge) {
  }
  @media screen and (min-width: $xxlarge) {
  }
}

.sl {
  color: $main-color-3;
}

#hHelper {
  height: 100%;
}
.psydoContent {
  display: none;
  position: relative;
  max-width: 700px;
  width: 30%;
  height: 100%;
  background-color: #ffffff;
  box-sizing: border-box;
  z-index: 10;
  overflow: hidden;
  padding: 10px;
  @media screen and (min-width: $large) {
    display: block;
  }
}

.psydoBox {
  width: 100%;
  height: 10%;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 5px 0;
}
.psydoBoxIn {
  width: 100%;
  height: 100%;
  background-color: rgb(214, 214, 214);
  box-sizing: border-box;
}

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

iframe {
  position: relative;
  z-index: 0;
}
.close-ad {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0;
  background: white;
}

#sizeBtn {
  display: none !important;
  cursor: grab;
  opacity: 1;
  position: absolute;
  top: 100%;
  left: 100%;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  z-index: 20;
  transform: translate(-50%, -50%) scale(1);
  justify-content: center;
  align-items: center;

  display: none;

  @media screen and (min-width: $large) {
    visibility: visible;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.is-dragging {
    cursor: grabbing !important;
    // transform: translate(-50%, -50%) scale(0.6);
    #sizeBtnCircle_1,
    #sizeBtnCircle_2 {
      transform: scale(0.6);
      animation: none;
      background-color: black;
    }
  }
}

#sizeBtnCircle_1 {
  transition: all 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-color: $main-color-3;
  // border: 4px solid $main-color-3;
  animation-name: blubb_1;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  z-index: 10;
}

#sizeBtnCircle_2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-color: $main-color-3;
  animation-name: blubb_2;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  z-index: 0;
}
.sizeBtnText {
  position: relative;
  z-index: 20;
  color: white;
}

@keyframes blubb_1 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes blubb_2 {
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    transform: scale(2);
    opacity: 0;
  }
}
</style>
