<template>
  <div
    id="adsmenubtn"
    ref="adsmenubtnIcon"
    :class="isAdsMenuOpen ? 'open' : ''"
    class="no-touch-out"
    @click.prevent="toggelMenu"
  >
    <div class="adsmenubtn__bg"></div>
    <div class="adsmenubtn__icon">
      <span id="s_1"></span>
      <span id="s_2"></span>
      <span id="s_3"></span>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";

import gsap from "gsap";
export default {
  setup() {
    const store = useStore();
    const adsmenubtnIcon = ref(null);
    const isAdsMenuOpen = computed(() => {
      return store.state.isAdsMenuOpen;
    });

    function toggelMenu() {
      if (isAdsMenuOpen.value) {
        closeMenu();
      } else {
        openMenu();
      }
    }
    function closeMenu() {
      store.commit("setAdsMenuState", false);
    }
    function openMenu() {
      store.commit("setAdsMenuState", true);
    }
    onMounted(() => {
      gsap.to("#adsmenubtn", { opacity: 1, duration: 1, delay: 0 });
    });
    return { isAdsMenuOpen, closeMenu, openMenu, toggelMenu, adsmenubtnIcon };
  },
};
</script>

<style lang="scss" scoped>
#adsmenubtn {
  position: relative;
  width: 30px;
  height: 20px;
  opacity: 0;
}
.t-menu {
  font-size: 80px;
}

.adsmenubtn__icon {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 200;
  transition: all 200ms 200ms ease-in-out;
  @media screen and (min-width: $medium) {
  }
  @media screen and (min-width: $large) {
  }
  @media screen and (min-width: $xlarge) {
  }
  @media screen and (min-width: $xxlarge) {
  }
}

.adsmenubtn__icon span {
  position: absolute;
  width: 30px;
  height: 4px;
  background-color: white;
  transition: all 200ms;
}

// animation
.open .adsmenubtn__icon {
  transform: rotate(45deg);

  & span {
    // background-color: black;
  }
}

.open #s_1 {
  top: 8px;
  left: 0;
  transform: rotate(-90deg);
}

.open #s_3 {
  width: 0;
}

.menu-content {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  align-items: center;
  overflow: hidden;
  transform: translate(-100%, 0);
}

.menu.open > .menu-content {
  width: 100vw;
  height: 100vh;
}

#s_1 {
  top: 0;
  left: 0;
}

#s_2 {
  top: 8px;
  left: 0;
}

#s_3 {
  top: 16px;
}

.adsmenubtn__bg {
  position: absolute;
  top: -46px;
  left: -12px;
  background: black;
  width: 54px;
  height: 90px;
  border-radius: 400px;
}
</style>
