<template>
  <div class="ads">
    <div class="ads__btn">
      <AdsMenuBtn />
    </div>
    <div class="ads__menu" :class="isAdsMenuOpen ? 'open' : ''">
      <AdsMenu />
    </div>
  
    <div class="ads__maincontent">
      <div class="ads__overview"><AdsOverview /></div>
      <div class="ads__preview">
        <AdsPreview v-if="store.state.activeComponent" />
      </div>
      <div class="ads__info"><AdsInfo /></div>
    </div>
  </div>
</template>

<script>
import AdsMenu from "./AdsMenu.vue";
import AdsMenuBtn from "./AdsMenuBtn.vue";
import AdsPreview from "./AdsPreview.vue";
import AdsOverview from "./AdsOverview.vue";
import AdsInfo from "./AdsInfo.vue";

import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";
export default {
  components: {
    AdsMenu,
    AdsMenuBtn,
    AdsPreview,
    AdsOverview,
    AdsInfo,
  },
  props: {
    dataObject: Array,
  },

  setup(props) {
      // store
      const store = useStore();
    const data = computed(() => {
      props.dataObject;
    });

    const isAdsMenuOpen = computed(() => {
      return store.state.isAdsMenuOpen;
    });

  
    // store
    return {
      data,
      store,
      isAdsMenuOpen
    };
  },
};
</script>

<style lang="scss">
.ads {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: grey;
}

.ads__menu {
  position: fixed;
  left: 0;
  width: 300px;
  height: 100%;
  overflow: hidden;
  background-color: white;
  z-index: 10;
  padding-top:40px;
  transform: translateX(-100%);
  &.open{
    transform: translateX(0%);
  }

  @media screen and (min-width: $small) {
    padding-top:0;
    transform: translateX(0) !important;
  }
}
.ads__maincontent {
  position: absolute;
  width: calc(100%);
  left: 0;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  @media screen and (min-width: $small) {
    position: absolute;
    width: calc(100% - 300px);
    left: 300px;
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.ads__preview {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $colour-secondary;
}
.AdsMenu {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.ads__topbox {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2000;
}

.ads__btn {
  z-index: 20;
  position: fixed;
  top: 20px;
  left: 20px;
  display: block;
  @media screen and (min-width: $small) {
    display: none;
  }
}
</style>
