<template>
  <div class="page page__ads">
    <AdsPage v-if="showAdPage" :name="name" />
  </div>
</template>

<script>
import AdsPage from "@/components/common/ads/AdsPage.vue";
import { onBeforeUnmount, onMounted, onUpdated, ref, watchEffect } from "@vue/runtime-core";

//gsap

import { useStore } from "vuex";

export default {
  components: {
    AdsPage,
  },

  setup() {
    const store = useStore();
    // DATAOBJECT

    const showAdPage = ref(false);

    const path = ref(store.state.campPath);
    const name = ref(store.state.campName);

    console.log(path, name);
    const dataObject = ref([]);

    function buidNewDataArray(data) {
      const map1 = data.map((el, index) => {
        el.id = index;
        el.path = path.value + el.path;
        return el;
      });
      dataObject.value = map1;
      setNewData();
    }

    function setNewData() {

      console.log("NEW DATA = ", dataObject.value);
      store.commit("setAdsDataObject", dataObject.value);
      showAdPage.value = true;
    }
    // DATAOBJECT END
    async function loadFileAndPrintToConsole(url) {
      try {
        const response = await fetch(url);
        const data = await response.json();
        buidNewDataArray(data);
      } catch (err) {
        console.error(err);
      }
    }

    onMounted(async () => {
      // Wir übergeben den Pfad und ergänzen is um das gezogenen JSON
      loadFileAndPrintToConsole(path.value + "dataObject.json");
    });

    watchEffect(()=>{
      // if(path.value){
      //     loadFileAndPrintToConsole(path.value + "dataObject.json");
      // }
    })

    onBeforeUnmount(() => {});

    onUpdated(() => {});

    return { store, dataObject, showAdPage, name };
  },
};
</script>
<style
  lang="
    scss"
></style>
