<template>
  <div class="adsbtn">
    <div
      v-if="time == 'ab'"
      class="adsbtn__btn"
      @click="setTestDay(time)"
      :class="store.state.forceTestDay == 'ab' ? 'isActive' : ''"
    >
    {{store.state.camp_1}}
    </div>
    <div
      v-if="time == 'heute'"
      class="adsbtn__btn"
      @click="setTestDay(time)"
      :class="store.state.forceTestDay == 'heute' ? 'isActive' : ''"
    >
    {{store.state.camp_2}}
    </div>
    <div
      v-if="time == 'begleitend'"
      class="adsbtn__btn"
      @click="setTestDay(time)"
      :class="store.state.forceTestDay == 'begleitend' ? 'isActive' : ''"
    >
      {{store.state.camp_3}}
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
  props: {
    time: String,
  },
  setup() {
    const store = useStore();

    let active = false;

    function setTestDay(el) {
      store.commit("setTestDay", el);
    }
    // store.commit("setTestDayFalse", false);
    return { store, setTestDay, active };
  },
};
</script>

<style lang="scss">
.adsbtn {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  padding: 5px;
}
.adsbtn__btn {
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgb(220, 220, 220);
  border-radius: 100px;

  &:hover {
    background: rgb(179, 179, 179);
  }
  &.isActive {
    background: $colour-secondary;
  }
}
</style>
