<template>
  <div class="adsmenu">
    <div class="adsmenu__wrapper">
      <div class="adsmenu__content">
        <div class="adsmenu__topbox">
          <div class="adsmenu__cat">
            HTML5 Ads
          </div>
    
          <div
            v-for="(banner, index) in data"
            :key="index"
            class="adsmenu__element"
            @click="menuClickHandler(banner, index)"
            :class="activeElement == index ? 'isActive' : ''"
          >
            <div class="adsmenu__id">
              {{ banner.id }}
            </div>
            <div class="adsmenu__type">
              {{ banner.type }}
            </div>
            <div class="adsmenu__size">
              {{ banner.size.w }} x {{ banner.size.h }}
            </div>
            <div class="adsmenu__motive">Motive {{ banner.motive }}</div>
            <div class="adsmenu__name">
              {{ banner.name }}
            </div>
          </div>
        </div>
        <div v-if="store.state.campButtons" class="adsmenu__bottombox">
          <AdsBtn :time="'ab'" />
          <AdsBtn :time="'heute'" />
          <AdsBtn :time="'begleitend'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdsBtn from "./AdsBtn.vue";
import { onMounted, ref } from "@vue/runtime-core";

import { useStore } from "vuex";
export default {
  components: {
    AdsBtn,
  },
  props: {
    dataObject: Array,
  },

  setup() {
    const data = ref([]);
    const activeElement = ref(null);
    const store = useStore();

    function menuClickHandler(e, index) {
      activeElement.value = index;
      store.commit("setActiveComponent", e);
    }

    onMounted(() => {
      data.value = store.state.adsDataObject;
      store.commit("setActiveComponent", data.value[0]);
      activeElement.value = 0;
    });

    return {store, data, menuClickHandler, activeElement };
  },
};
</script>

<style lang="scss">
.adsmenu {
  position: relative;
  height: 100%;
  width: 100%;


}
.adsmenu__wrapper {
  position: relative;
  height: 100%;
  width: 100%;

}
.adsmenu__content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  overflow: hidden;
}

.adsmenu__element {
  position: relative;
  cursor: pointer;
  padding: 10px;
  &:hover {
    background: rgb(228, 228, 228);
  }

  &.isActive {
    background-color: $colour-secondary;
  }
}

.adsmenu__name {
  padding-top: 3px;
  font-size: 10px;
  width: 100%;
  hyphens: auto;
  font-style: italic;
}
.adsmenu__id {
  padding: 10px 10px;
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
}
.adsmenu__type {
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
}

.adsmenu__cat {
  padding: 20px 0;
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  background-color: white;

  font-weight: 900;
}
.adsmenu__size {
  font-weight: 600;
}
.adsmenu__motive {
  font-weight: 400;
}
.adsmenu__topbox {
  flex: 1 1 auto;
  overflow: auto;
}
.adsmenu__bottombox {
  background-color: white;
  padding: 30px 10px;
  flex: 1 1 auto;
  text-align: center;
}

</style>
