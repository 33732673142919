<template>
  <div class="loader">
    loading
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 100px;
  border: 1px solid $main-color-3;
  color: black;
  animation-name: example;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@keyframes example {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
